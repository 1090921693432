import React from "react";

const useLoggedInUserFetch = () => {
  return {
    contactId: 2001,
    firstName: "Marguerite",
    lastName: "E",
    email: "marguerite.e.sheldon@pwc.com",
  };
};

export default useLoggedInUserFetch;
