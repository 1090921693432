import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
} from "react";
import CustomSelectField from "./FormFields/CustomSelectField";
import { formFieldLabels, formFieldNames } from "../Constants";
import { useState } from "react";
import useMeetingTypeListFetch from "../hooks/useMeetingTypeListFetch";

const MeetingTypeField = (props, ref) => {
  const { initialMeetingTypeId = null } = props;
  const [meetingType, setMeetingType] = useState(null);
  const [error, setError] = useState("");

  let { meetingTypeList, loading: isTypeListLoading } =
    useMeetingTypeListFetch();
  const validateMeetingType = () => {
    if (!meetingType) {
      setError("Please select meeting type");
    }
  };

  const handleMeetingType = useCallback((value) => {
    setMeetingType(value);
    if (!!value) {
      setError("");
    }
  });

  const getMeetingType = () => {
    validateMeetingType();
    return meetingType;
  };

  useEffect(() => {
    if (!!initialMeetingTypeId && !!meetingTypeList) {
      let _foundMeetingType = meetingTypeList.find(
        (element) => element.meetingTypeId == initialMeetingTypeId
      );
      setMeetingType(_foundMeetingType);
    }
  }, [initialMeetingTypeId, meetingTypeList]);

  useImperativeHandle(ref, () => ({
    getMeetingType,
  }));
  return (
    <CustomSelectField
      error={error}
      label={formFieldLabels.MEETING_TYPE}
      name={formFieldNames.MEETING_TYPE}
      selectedOption={meetingType}
      setSelectedOption={handleMeetingType}
      optionList={meetingTypeList}
      isListLoading={isTypeListLoading}
      notFoundMessage="Meeting type does'nt match"
      nameString="typeName"
      valueString="meetingTypeId"
      isDisabled={isTypeListLoading}
    />
  );
};

export default forwardRef(MeetingTypeField);
