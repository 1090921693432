export const ApiEndpoints = {
    GET_MEETING_TYPES:"/meetingassistant/type",
    GET_MEETING_STATUS:"/meetingassistant/status",
    GET_MEETING_SOURCE:"/meetingassistant/source",
    GET_ALL_MEETINGS_BY_FILTERS:"/meetingassistant/meetings",
    DELETE_MEETING_BY_ID:"/meetingassistant",
    GET_MEETING_BY_ID: "/meetingassistant/meeting/",
    GET_OUTPUT_TEMPLATE: "/meetingassistant/outputtemplates",
    GET_AGENDA_BY_MEETING_ID: "/meetingassistant/meeting/",
    GET_ALL_CONTACTS: "/contact",
  CREATE_MEETING: "/meetingassistant",
};
