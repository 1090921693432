import { Button } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { useMeetingRegistrationStyle } from "../styles/summarix.style";
import useMeetingFetch from "../hooks/useMeetingFetch";
import MeetingTitleField from "../Components/MeetingTitleField";
import MeetingTypeField from "../Components/MeetingTypeField";
import MeetingTimeFields from "../Components/MeetingTimeFields";
import MeetingAttendeesField from "../Components/MeetingAttendeesField";
import useLoggedInUserFetch from "../hooks/useLoggedInUserFetch";
import MeetingAgendaSection from "../Components/MeetingAgendaSection";
import StepForm from "../Components/MultiStepUI/StepForm";
import IsAIAssistedField from "../Components/IsAIAssistedField";
import OutputTemplatesField from "../Components/OutputTemplatesField";
import MeetingLocationField from "../Components/MeetingLocationField";

const MeetingRegistration = (props) => {
  let { id = 12, content } = props;

  let {
    data: meeting,
    setData: setMeeting,
    isLoading: isMeetingLoading,
  } = useMeetingFetch(id);
  let meetingOrganiser = useLoggedInUserFetch();

  let classes = useMeetingRegistrationStyle();

  const meetingTitleRef = useRef(null);
  const meetingTypeRef = useRef(null);
  const meetingTimeRef = useRef(null);
  const meetingAttendeesRef = useRef(null);
  const outputTemplateRef = useRef(null);
  const meetingLocationRef = useRef(null);
  const isAIAssistedRef = useRef(null);
  const meetingAgendaRef = useRef(null);

  const handleOnFirstPageNext = () => {
    let meetingTitle = meetingTitleRef.current?.getMeetingTitle();
    let meetingType = meetingTypeRef.current?.getMeetingType();
    let startDateTime = meetingTimeRef.current?.getStartDateTime();
    let endDateTime = meetingTimeRef.current?.getEndDateTime();
    let attendees = meetingAttendeesRef.current?.getMeetingAttendees();
    let isAIAssisted = isAIAssistedRef.current?.getIsAIAssisted();
    let outputTemplate = outputTemplateRef.current?.getOutputTemplate();
    let meetingLocation = meetingLocationRef.current?.getMeetingLocation();

    if (
      !meetingTitle ||
      !meetingType ||
      !startDateTime ||
      !endDateTime ||
      attendees.length == 0 ||
      !outputTemplate
    ) {
      return false;
    }

    setMeeting((prevMeeting) => {
      return {
        ...prevMeeting,
        id: id !== null ? id : undefined,
        meetingTitle,
        meetingType,
        startDateTime,
        endDateTime,
        attendees,
        meetingOrganiser,
        isAIAssisted,
        outputTemplate,
        meetingLocation,
      };
    });

    return true;
  };

  const handleOnSecondPageBack = () => {
    setMeeting((prevMeeting) => {
      return {
        ...prevMeeting,
        agendaAndMOM: meetingAgendaRef.current?.getAgendaAndMOM({
          validate: false,
        }),
      };
    });
    return true;
  };

  const handleSaveAndCreateMeeting = () => {
    let agendaAndMOM = meetingAgendaRef.current?.getAgendaAndMOM({
      validate: true,
    });
    if (agendaAndMOM.length == 0) {
      return false;
    }
    setMeeting((prevMeeting) => {
      return {
        ...prevMeeting,
        agendaAndMOM,
      };
    });

    let toSaveFormData = {
      ...meeting,
      agendaAndMOM,
      startDateTime: new Date(meeting?.startDateTime).toISOString(),
      endDateTime: new Date(meeting?.endDateTime).toISOString(),
    };

    console.log(toSaveFormData);
  };

  const initialStartDateTime = (() => {
    const _todayDateTime = new Date();
    const offset = _todayDateTime.getTimezoneOffset() * 60000;
    return (
      meeting?.meetingStartDate ||
      new Date(_todayDateTime - offset).toISOString().slice(0, 16)
    );
  })();

  const initialEndDateTime = (() => {
    const _todayDateTime = new Date();
    _todayDateTime.setMinutes(_todayDateTime.getMinutes() + 30);
    const offset = _todayDateTime.getTimezoneOffset() * 60000;
    return (
      meeting?.meetingEndDate ||
      new Date(_todayDateTime - offset).toISOString().slice(0, 16)
    );
  })();

  return (
    <StepForm>
      <StepForm.Step onNext={handleOnFirstPageNext}>
        <div className={classes.meetingRegistrationForm}>
          <MeetingTitleField
            ref={meetingTitleRef}
            initialMeetingTitle={meeting?.meetingTitle}
          />
          <MeetingTypeField
            ref={meetingTypeRef}
            initialMeetingTypeId={meeting?.meetingTypeId}
          />
          <MeetingTimeFields
            ref={meetingTimeRef}
            initialStartDateTime={initialStartDateTime}
            initialEndDateTime={initialEndDateTime}
          />
          <MeetingAttendeesField
            ref={meetingAttendeesRef}
            initialAttendees={meeting?.attendees.filter((item) => {
              return item.contactId !== meetingOrganiser.contactId;
            })}
          />
          <MeetingLocationField
            ref={meetingLocationRef}
            initialMeetingLocation={meeting?.location}
          />
          <OutputTemplatesField
            ref={outputTemplateRef}
            initialOutputTemplateId={meeting?.meetingOutputTemplate}
          />
          <IsAIAssistedField
            ref={isAIAssistedRef}
            initialValue={meeting?.isAIAssisted}
          />
        </div>
      </StepForm.Step>
      <StepForm.Step
        onBack={handleOnSecondPageBack}
        buttons={[
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            key="custom1"
            onClick={handleSaveAndCreateMeeting}
          >
            Save
          </Button>,
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            key="custom2"
            onClick={() => console.log("calling api for pre mom")}
          >
            Download Pre MOM
          </Button>,
        ]}
      >
        <div className={classes.meetingRegistrationForm}>
          <MeetingAgendaSection
            ref={meetingAgendaRef}
            meetingFormAgendas={meeting?.agendaAndMOM}
            meetingId={id}
          />
        </div>
      </StepForm.Step>
    </StepForm>
  );
};

export default MeetingRegistration;
