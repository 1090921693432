import { useEffect, useState } from "react";
import { getAllMeetingTypes } from "../ABM_API";

const useMeetingTypeListFetch = () => {
  const [meetingTypeList, setMeetingTypeList] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const res = await getAllMeetingTypes();
      setMeetingTypeList(res.data);
      setLoading(false);
    };

    fetchData();
  }, []);

  return { meetingTypeList, loading };
};

export default useMeetingTypeListFetch;
