import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { useMeetingTimeFieldsStyle } from "../styles/summarix.style";
import { formFieldLabels, formFieldNames } from "../Constants";
import CustomTimeField from "./FormFields/CustomTimeField";

const MeetingTimeFields = (props, ref) => {
  const { initialStartDateTime = "", initialEndDateTime = "" } = props;
  const classes = useMeetingTimeFieldsStyle();

  let initialDuration = (() => {
    let _initialStartTime = new Date(initialStartDateTime);
    let _initialEndTime = new Date(initialEndDateTime);
    return Math.abs(_initialStartTime - _initialEndTime);
  })();

  const [startDateTime, setStartDateTime] = useState(initialStartDateTime);
  const [endDateTime, setEndDateTime] = useState(initialEndDateTime);
  const [duration, setDuration] = useState(initialDuration);
  const [startTimeError, setStartTimeError] = useState("");
  const [endTimeError, setEndTimeError] = useState("");

  useEffect(() => {
    setStartDateTime(initialStartDateTime);
    setEndDateTime(initialEndDateTime);
  }, [initialStartDateTime, initialEndDateTime]);

  const handleStartDateChange = (e) => {
    let _newStartTime = new Date(e.target.value);
    let newEndDate = new Date(_newStartTime.getTime() + duration);
    const offset = newEndDate.getTimezoneOffset() * 60000;
    setEndDateTime(new Date(newEndDate - offset).toISOString().slice(0, 16));

    setStartDateTime(e.target.value);
  };

  let handleEndDateChange = (e) => {
    let _newEndTime = new Date(e.target.value);
    let _startTime = new Date(startDateTime);
    if (_newEndTime > _startTime) {
      setEndDateTime(e.target.value);
      setDuration(Math.abs(_newEndTime - _startTime));
    }
  };

  const validateStartDateTime = (time) => {
    if (!time) {
      setStartTimeError("Please enter start time");
    }
  };

  const validateEndDateTime = (time) => {
    if (!time) {
      setEndTimeError("Please enter end time");
    }
  };

  const getStartDateTime = () => {
    validateStartDateTime(startDateTime);
    return startDateTime;
  };

  const getEndDateTime = () => {
    validateEndDateTime(endDateTime);
    return endDateTime;
  };

  useImperativeHandle(ref, () => ({
    getStartDateTime,
    getEndDateTime,
  }));

  return (
    <div className={classes.timeFields}>
      <CustomTimeField
        error={startTimeError}
        label={formFieldLabels.MEETING_START_DATE_TIME}
        name={formFieldNames.MEETING_START_DATE_TIME}
        value={startDateTime}
        onChange={handleStartDateChange}
      />
      <CustomTimeField
        error={endTimeError}
        label={formFieldLabels.MEETING_END_DATE_TIME}
        name={formFieldNames.MEETING_END_DATE_TIME}
        value={endDateTime}
        onChange={handleEndDateChange}
      />
      <MeetingDuration duration={duration} />
    </div>
  );
};

const MeetingDuration = (props) => {
  const { duration } = props;
  function formatDuration(ms) {
    const minutes = Math.floor(ms / (1000 * 60)) % 60;
    const hours = Math.floor(ms / (1000 * 60 * 60)) % 24;
    const days = Math.floor(ms / (1000 * 60 * 60 * 24));

    const dayStr = days > 0 ? `${days}d ` : "";
    const hourStr = hours > 0 ? `${hours}h ` : "";
    const minuteStr = minutes > 0 ? `${minutes}m` : "";

    return `${dayStr}${hourStr}${minuteStr}`.trim();
  }
  return (
    <div>
      <div>Duration</div>
      <span>{formatDuration(duration)}</span>
    </div>
  );
};

export default forwardRef(MeetingTimeFields);
