import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import useOutputTemplatesFetch from "../hooks/useOutputTemplatesFetch";
import CustomSelectField from "./FormFields/CustomSelectField";
import { formFieldLabels, formFieldNames } from "../Constants";

const OutputTemplatesField = (props, ref) => {
  const { initialOutputTemplateId = null } = props;
  const [outputTemplate, setOutputTemplate] = useState(null);
  const [error, setError] = useState("");
  let {
    data: outputTemplates,
    isLoading: outputTemplatesLoading,
    error: outputTemplatesError,
  } = useOutputTemplatesFetch();

  const handleSelectOutputTemplate = (value) => {
    setOutputTemplate(value);
    if (!!value) {
      setError("");
    }
  };

  const validateOutputTemplate = () => {
    if (!outputTemplate) {
      setError("Please select Output template");
    }
  };

  const getOutputTemplate = () => {
    validateOutputTemplate();
    return outputTemplate;
  };

  useEffect(() => {
    if (!!initialOutputTemplateId && outputTemplates.length != 0) {
      let _foundOutputTemplate = outputTemplates.find(
        (element) =>
          element.meetingMOMHeaderTemplateId == initialOutputTemplateId
      );
      setOutputTemplate(_foundOutputTemplate);
    }
  }, [initialOutputTemplateId, outputTemplates]);

  useImperativeHandle(ref, () => ({
    getOutputTemplate,
  }));
  return (
    <CustomSelectField
      label={formFieldLabels.MEETING_OUTPUT_TEMPLATE}
      name={formFieldNames.MEETING_OUTPUT_TEMPLATE}
      selectedOption={outputTemplate}
      setSelectedOption={handleSelectOutputTemplate}
      optionList={outputTemplates}
      isListLoading={outputTemplatesLoading}
      error={error}
      notFoundMessage="Output template does'nt match"
      nameString="templateName"
      valueString="meetingMOMHeaderTemplateId"
      isDisabled={outputTemplatesLoading}
    />
  );
};

export default forwardRef(OutputTemplatesField);
