import React, { useEffect, useState, useMemo } from "react";
import { getMeetingById } from "../ABM_API";

const useMeetingFetch = (id) => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
        setIsLoading(true);
        const res = await getMeetingById(id);
        setData(res.data);
        setIsLoading(false);
    };

    fetchData();
  }, [id]);

  return { data, setData, isLoading };
};

export default useMeetingFetch;
