import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import useUsersFetch from "../hooks/useUsersFetch";
import useLoggedInUserFetch from "../hooks/useLoggedInUserFetch";
import CustomOptionSetField from "../Components/FormFields/CustomOptionSetField";
import { formFieldLabels, formFieldNames } from "../Constants";

const MeetingAttendeesField = (props, ref) => {
  const { initialAttendees = [] } = props;

  const { usersList: users, isUsersListLoading } = useUsersFetch();
  const meetingOrganiser = useLoggedInUserFetch();

  const [contactList, setContactList] = useState([]);
  const [selectedAttendees, setSelectedAttendees] = useState(initialAttendees);
  const [error, setError] = useState("");

  useEffect(() => {
    if (users.length != 0) {
      let removedOrganiserList = users.filter(
        (item) => meetingOrganiser.contactId != item.contactId
      );
      setContactList(removedOrganiserList);
    }
  }, [users]);

  const validateMeetingAttendees = () => {
    if (selectedAttendees.length == 0) {
      setError("Please select attendees");
    }
  };

  const getMeetingAttendees = () => {
    validateMeetingAttendees();
    return selectedAttendees;
  };

  const handleSelectAttendees = (value) => {
    if (value.length != 0) {
      setError("");
    }
    setSelectedAttendees(value);
  };

  useEffect(() => {
    setSelectedAttendees(initialAttendees);
  }, [initialAttendees.toString()]);

  useImperativeHandle(ref, () => ({
    getMeetingAttendees,
  }));

  return (
    <CustomOptionSetField
      label={formFieldLabels.MEETING_ATTENDEES}
      name={formFieldNames.MEETING_ATTENDEES}
      selectedOptionSets={selectedAttendees}
      setSelectedOptionSets={handleSelectAttendees}
      optionList={contactList}
      isListLoading={isUsersListLoading}
      value={10}
      error={error}
      notFoundMessage="user not Found"
      nameString="fullName"
      valueString="contactId"
    />
  );
};

export default forwardRef(MeetingAttendeesField);
