import { Checkbox, FormControlLabel } from "@material-ui/core";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";

const IsAIAssistedField = (props, ref) => {
  const { initialValue = false } = props;
  const [isAIAssisted, setisAIAssisted] = useState(initialValue);

  const handleChange = (e) => {
    setisAIAssisted(e.target.checked);
  };

  useEffect(() => {
    setisAIAssisted(initialValue);
  }, [initialValue]);

  useImperativeHandle(ref, () => ({
    getIsAIAssisted: () => isAIAssisted,
  }));
  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={isAIAssisted}
          onChange={handleChange}
          name="checkedB"
          color="primary"
        />
      }
      label="Is AI Assisted"
    />
  );
};

export default forwardRef(IsAIAssistedField);
