import { Button } from "@material-ui/core";
import React, { forwardRef, useState } from "react";
import { useStepFormStyle } from "../../styles/summarix.style";

const StepForm = ({ children }) => {
  const classes = useStepFormStyle();
  const [currentStep, setCurrentStep] = useState(0);
  const totalSteps = React.Children.count(children);

  const nextStep = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    let toNext = React.Children.toArray(children)[currentStep]?.props.onNext();
    if (toNext && currentStep < totalSteps - 1) {
      setCurrentStep(currentStep + 1);
    }
  };

  const prevStep = () => {
    let toBack = React.Children.toArray(children)[currentStep]?.props.onBack();
    if (toBack && currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  return (
    <div>
      <div>{children[currentStep]}</div>
      <div className={classes.StepFormButtonArea}>
        <div className={classes.BackButtonArea}>
          {currentStep > 0 && (
            <Button variant="outlined" onClick={prevStep}>
              Back
            </Button>
          )}
        </div>
        <div className={classes.NextButtonArea}>
          {currentStep < totalSteps - 1 && (
            <Button
              key="next1"
              variant="contained"
              color="primary"
              onClick={nextStep}
            >
              Next
            </Button>
          )}
        </div>
        <div className={classes.customButtons}>
          {React.Children.toArray(children)[currentStep]?.props.buttons}
        </div>
      </div>
    </div>
  );
};

const Step = ({ children }) => {
  return <div>{children}</div>;
};

StepForm.Step = Step;

export default StepForm;
