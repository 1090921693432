export const formFieldNames = {
  MEETING_TITLE: "meeting_title",
  MEETING_START_DATE_TIME: "meeting_start_date_time",
  MEETING_END_DATE_TIME: "meeting_end_date_time",
  MEETING_ATTENDEES: "meeting_attendees",
  MEETING_TYPE: "meeting_type",
  MEETING_ORGANISER: "meeting_organiser",
  MEETING_AGENDA: "meeting_agenda",
  MEETING_OUTPUT_TEMPLATE: "output_template",
  MEETING_LOCATION: "meeting_location",
  MEETING_JOINURL: "meeting_joinUrl",
};

export const formFieldLabels = {
  MEETING_TITLE: "Meeting Title*",
  MEETING_START_DATE_TIME: "Start Date Time*",
  MEETING_END_DATE_TIME: "End Date Time*",
  MEETING_ATTENDEES: "Add Meeting Attendees*",
  MEETING_TYPE: "Meeting Type*",
  MEETING_ORGANISER: "Meeting Organiser",
  MEETING_AGENDA: "Meeting Agenda*",
  MEETING_OUTPUT_TEMPLATE: "Output Template*",
  MEETING_LOCATION: "Location",
  MEETING_JOINURL: "Meeting Join URL",
};

export const AgendaMOMType = {
  PRE_MOM: "pre_mom",
  POST_MOM: "post_mom",
};

export const ActionType = Object.freeze({
  ADD: "add",
  EDIT: "edit",
});

export const StatusMessage = Object.freeze({
  SUCCESS: "Success",
  FAILED: "Failed",
});


export const STATUS = {
  AGENDA_PENDING: "Agenda pending",
  MEETING_AWAITED:"Meeting Awaited",
  SUMMARY_GENERATED: "Summary Generated",
  SUMMARY_PENDING:"Summary Pending",
  GENERATING_SUMMARY:"Generating Summary",
  SUMMARY_GENERATING_FAIL:"Summary Generating fail",
  CANCELLED: "Cancelled",
  MINUTES_APPROVED:"Minutes Approved",
};