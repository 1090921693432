import { useEffect, useState } from "react";
import { getAgendaByMeetingId } from "../ABM_API";

const useMeetingAgendasFetch = ({ meetingId, meetingFormAgendas = null }) => {
  const [meetingAgendas, setMeetingAgendas] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (meetingId != null) {
      const fetchData = async () => {
        setLoading(true);
        if (!meetingFormAgendas) {
          const res = await getAgendaByMeetingId(meetingId);
          setMeetingAgendas((pre) => [...pre, ...res.data]);
        } else {
          setMeetingAgendas(() =>
            !meetingFormAgendas ? [...meetingAgendas] : [...meetingFormAgendas]
          );
        }
        setLoading(false);
      };

      fetchData();
    }
  }, [meetingId]);

  return { meetingAgendas, setMeetingAgendas, loading };
};

export default useMeetingAgendasFetch;
