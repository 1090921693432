import { TextField } from "@material-ui/core";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import CustomTextField from "./FormFields/CustomTextField";
import { formFieldLabels, formFieldNames } from "../Constants";

const MeetingLocationField = (props, ref) => {
  const { initialMeetingLocation = "" } = props;
  const [meetingLocation, setMeetingLocation] = useState(
    initialMeetingLocation
  );

  const handleMeetingLocationChange = (e) => {
    setMeetingLocation(e.target.value);
  };

  useEffect(() => {
    setMeetingLocation(initialMeetingLocation);
  }, [initialMeetingLocation]);

  useImperativeHandle(ref, () => ({
    getMeetingLocation: () => meetingLocation,
  }));
  return (
    <CustomTextField
      label={formFieldLabels.MEETING_LOCATION}
      name={formFieldNames.MEETING_LOCATION}
      value={meetingLocation}
      onChange={handleMeetingLocationChange}
    />
  );
};

export default forwardRef(MeetingLocationField);
