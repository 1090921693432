import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import AgendaAndMOM from "./FormFields/AgendaAndMOM";
import { Button } from "@material-ui/core";
import AddAgendaField from "./AddAgendaField";
import { useMeetingAgendaSectionStyle } from "../styles/summarix.style";
import useMeetingAgendasFetch from "../hooks/useMeetingAgendasFetch";
import getPreviousMeetingMOM from "../ABM_API/getPreviousMeetingMOM";

const MeetingAgendaSection = (props, ref) => {
  const { meetingId, meetingFormAgendas } = props;
  const classes = useMeetingAgendaSectionStyle();
  const [error, setError] = useState("");

  const {
    meetingAgendas,
    setMeetingAgendas,
    loading: isAgendaLoading,
  } = useMeetingAgendasFetch({
    meetingId,
    meetingFormAgendas,
  });

  const fetchPreviousMeetingMOM = async () => {
    let previousMeetingMOM = await getPreviousMeetingMOM();
    setMeetingAgendas(previousMeetingMOM.data);
  };

  const validateAgendaAndMOM = () => {
    let _error = "";
    if (meetingAgendas.length == 0) {
      _error = "Please add agenda";
    }

    let isAnyAgendaEmpty = meetingAgendas.some((element) => {
      return !element.agendaTitle;
    });

    if(isAnyAgendaEmpty){
      _error = "Agenda can not be empty"
    }

    if (!!_error) {
      setError(_error);
      return false;
    }

    return true;
  };

  const getAgendaAndMOM = ({ validate }) => {
    let toProceed = true;
    if (validate) {
      toProceed = validateAgendaAndMOM();
    }

    if (!toProceed) {
      return false;
    }

    let _meetingAgendas = meetingAgendas.map((element, index) => {
      return { ...element, orderId: index };
    });
    setMeetingAgendas(_meetingAgendas);
    return _meetingAgendas;
  };

  const handleAgendaPoints = (value) => {
    if (value.length != 0) {
      setError("");
    }
    setMeetingAgendas(value);
  };

  useEffect(() => {
    if (meetingAgendas.length != 0) {
      setError("");
    }
  }, [meetingAgendas]);

  useImperativeHandle(ref, () => ({
    getAgendaAndMOM,
  }));

  return (
    <div>
      <div className={classes.previousMOMButtonArea}>
        <Button
          onClick={fetchPreviousMeetingMOM}
          variant="contained"
          color="primary"
          disabled={isAgendaLoading}
        >
          Pull previous meeting MOM
        </Button>
      </div>
      <AddAgendaField
        error={error}
        meetingId={meetingId}
        setAgendaPoints={handleAgendaPoints}
      />
      <AgendaAndMOM
        points={meetingAgendas}
        isAgendaLoading={isAgendaLoading}
        setPoints={handleAgendaPoints}
      />
    </div>
  );
};

export default forwardRef(MeetingAgendaSection);
