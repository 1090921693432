import * as httpCall from "../../../../../../Utils/apis/apis";
import { httpPost } from "../../../../../../Utils/apis/apis";
import { ApiEndpoints } from "./ApiEndpoints";

export const getAllMeetingTypes = async () => {
    const response = await httpCall.httpGet(ApiEndpoints.GET_MEETING_TYPES);
    return response;
  };
  export const getAllMeetingStatus = async () => {
    const response = await httpCall.httpGet(ApiEndpoints.GET_MEETING_STATUS);
    return response;
  };
  export const getAllMeetingSources = async () => {
    const response = await httpCall.httpGet(ApiEndpoints.GET_MEETING_SOURCE);
    return response;
  };
  export const getAllMeetingsByFilters = async (urlApi) => {
    const response = await httpCall.httpGet(urlApi);
    return response;
  };  
  
  export const deleteMeetingById = async (meetingId) =>{
    const response = await httpCall.httpDelete(
      `${ApiEndpoints.DELETE_MEETING_BY_ID}/meeting/${meetingId}`
    );
    return response;
  } 
  


export const getOutputTemplates = async () => {
  const response = await httpCall.httpGet(
    `${ApiEndpoints.GET_OUTPUT_TEMPLATE}`
  );
  return response;
};

export const getAgendaByMeetingId = async (meetingId) => {
  const response = await httpCall.httpGet(
    `${ApiEndpoints.GET_AGENDA_BY_MEETING_ID}/${meetingId}/agenda`
  );
  return response;
};


export const getMeetingById = async (meetingId) => {
  const response = await httpCall.httpGet(
    `${ApiEndpoints.GET_MEETING_BY_ID}${meetingId}`
  );
  return response;
};
export const getAllContacts = async () => {
  const response = await httpCall.httpGet(`${ApiEndpoints.GET_ALL_CONTACTS}`);
  return response;
};

export const createMeeting = async (reqBody) => {
  const response = await httpCall.httpPost(
    `${ApiEndpoints.CREATE_MEETING}`,
    reqBody
  );
  return response;
};
