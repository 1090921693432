import { CircularProgress, makeStyles, TextField } from "@material-ui/core";
import React, { useEffect, useMemo, useRef, useState } from "react";
import CloseIcon from "@material-ui/icons/Close";
import { useCustomOptionSetFieldStyle } from "../../styles/summarix.style";

const CustomOptionSetField = (props) => {
  const classes = useCustomOptionSetFieldStyle();
  const {
    label,
    name,
    optionList,
    isListLoading,
    selectedOptionSets,
    setSelectedOptionSets,
    notFoundMessage,
    nameString,
    valueString,
    error,
  } = props;
  const [fieldValue, setFieldValue] = useState("");
  const [isListVisible, setIsListVisible] = useState(false);
  const [listToShow, setListToShow] = useState(optionList);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const wrapperRef = useRef(null);

  const nonSelectedOptions = (() => {
    if (selectedOptionSets.length == 0) {
      return optionList;
    }
    let nonSelectedOptions = [];
    optionList.forEach((user) => {
      let foundUser = selectedOptionSets.find(
        (option) => user[valueString] == option[valueString]
      );
      if (!foundUser) {
        nonSelectedOptions.push(user);
      }
    });

    return nonSelectedOptions;
  })();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setIsListVisible(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  useEffect(() => {
    setListToShow(nonSelectedOptions);
  }, [optionList]);

  const handleFieldChange = (e) => {
    setFieldValue(e.target.value);
    if (e.target.value !== "") {
      let searchedResult = nonSelectedOptions.filter((item) =>
        item[nameString]
          .toLowerCase()
          .includes(e.target.value.trim().toLowerCase())
      );

      setListToShow(searchedResult);
      setIsListVisible(true);
    } else {
      setListToShow(nonSelectedOptions);
    }
    setSelectedIndex(0);
  };

  const handleFieldClick = (e) => {
    if (!e.target.value) {
      setListToShow(nonSelectedOptions);
      setIsListVisible(true);
    }
  };

  const handleFieldBlur = (e) => {
    if (listToShow.length == 0) {
      setIsListVisible(false);
    }
  };

  const onOptionSelected = (option) => {
    setSelectedOptionSets((selectedOption) => {
      return [...selectedOption, option];
    });
    setFieldValue("");
    setIsListVisible(false);
    setSelectedIndex(0);
  };

  const handleRemoveOption = (value) => {
    setSelectedOptionSets((prev) => {
      return prev.filter((option) => {
        return option[valueString] != value;
      });
    });
  };

  const handleKeyDown = (e) => {
    if (listToShow.length === 0) return;
    if (e.key === "ArrowDown") {
      setSelectedIndex((prevIndex) => {
        return prevIndex + 1 == listToShow.length ? 0 : prevIndex + 1;
      });
    } else if (e.key === "ArrowUp") {
      setSelectedIndex((prevIndex) => {
        return prevIndex == 0 ? listToShow.length - 1 : prevIndex - 1;
      });
    } else if (e.key === "Enter") {
      if (selectedIndex >= 0) {
        onOptionSelected(listToShow[selectedIndex]);
      }
    }
  };

  return (
    <div className={classes.optionSetField}>
      <TextField
        label={label}
        name={name}
        value={fieldValue}
        onChange={handleFieldChange}
        onKeyDown={handleKeyDown}
        autoComplete="off"
        error={!!error}
        helperText={error}
        onBlur={handleFieldBlur}
        onClick={handleFieldClick}
        fullWidth
      />
      {isListVisible && !isListLoading && (
        <div
          ref={wrapperRef}
          className={`${classes.options} ${
            listToShow.length > 3 ? classes.overflowYScroll : ""
          }`}
        >
          {listToShow.map((item, index) => (
            <ClickableOption
              key={index}
              listToShow={listToShow}
              item={item}
              classes={classes}
              onOptionSelected={onOptionSelected}
              selectedIndex={selectedIndex}
              nameString={nameString}
              valueString={valueString}
            />
          ))}
          {listToShow.length == 0 && (
            <div className={classes.padding1}>{notFoundMessage}</div>
          )}
        </div>
      )}
      {isListLoading && isListVisible && (
        <div
          ref={wrapperRef}
          className={`${classes.padding2} ${classes.options}`}
        >
          <CircularProgress size={20} />
        </div>
      )}

      {selectedOptionSets.length != 0 && (
        <div id="addedOptionsList" className={classes.addedOptionsList}>
          {selectedOptionSets?.map((item, index) => (
            <AddedOption
              key={index}
              name={item[nameString]}
              value={item[valueString]}
              handleRemoveOption={handleRemoveOption}
            />
          ))}
        </div>
      )}
    </div>
  );
};

const ClickableOption = (props) => {
  const {
    listToShow,
    item,
    valueString,
    nameString,
    classes,
    onOptionSelected,
    selectedIndex,
  } = props;
  return (
    <div
      className={
        listToShow[selectedIndex][valueString] == item[valueString]
          ? classes.selectedOptionItem
          : classes.optionItem
      }
      onClick={() => {
        onOptionSelected(item);
      }}
      value={item[valueString]}
    >
      {item[nameString]}
    </div>
  );
};

const AddedOption = (props) => {
  let classes = useCustomOptionSetFieldStyle();
  const { name, value, handleRemoveOption } = props;
  return (
    <div className={classes.addedOption}>
      <span>{name}</span>
      <CloseIcon
        className={classes.closeIcon}
        onClick={() => {
          handleRemoveOption(value);
        }}
        fontSize="small"
      />
    </div>
  );
};

export default CustomOptionSetField;
