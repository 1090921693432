import React, {
  useImperativeHandle,
  useState,
  forwardRef,
  useEffect,
} from "react";
import { formFieldLabels, formFieldNames } from "../Constants";
import CustomTextField from "./FormFields/CustomTextField";

const MeetingTitleField = (props, ref) => {
  let { initialMeetingTitle = "" } = props;
  const [meetingTitle, setMeetingTitle] = useState(initialMeetingTitle);
  const [error, setError] = useState("");
  const handleFieldChange = (e) => {
    setMeetingTitle((pre) => e.target.value);
    if (!!meetingTitle) {
      setError("");
    }
  };

  useEffect(() => {
    setMeetingTitle(initialMeetingTitle);
  }, [initialMeetingTitle]);

  const validateMeetingTitle = () => {
    if (!meetingTitle) {
      setError("Please fill meeting title");
      return false;
    }
  };

  const getMeetingTitle = () => {
    let validatedTitle = validateMeetingTitle();
    return meetingTitle;
  };

  useImperativeHandle(ref, () => ({
    getMeetingTitle,
  }));
  return (
    <CustomTextField
      label={formFieldLabels.MEETING_TITLE}
      name={formFieldNames.MEETING_TITLE}
      value={meetingTitle}
      error={error}
      onChange={handleFieldChange}
    />
  );
};

export default forwardRef(MeetingTitleField);
