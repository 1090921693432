import { useEffect, useState } from "react";
import { StatusMessage } from "../Constants";
import { getOutputTemplates } from "../ABM_API";

const useOutputTemplatesFetch = (id) => {
  const [outputTemplates, setOutputTemplates] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const handleResponse = (res) => {
    switch (res.statusMessage) {
      case StatusMessage.SUCCESS:
        setOutputTemplates(res.data);
        break;
      case StatusMessage.FAILED:
        setError(res.errorMsg);
        break;
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const res = await getOutputTemplates();
      handleResponse(res);
      setIsLoading(false);
    };

    fetchData();
  }, [id]);

  return { data:outputTemplates, isLoading, error };
};

export default useOutputTemplatesFetch;
