import { CircularProgress, TextField } from "@material-ui/core";
import { Delete, DragHandle, ExpandLess, ExpandMore } from "@material-ui/icons";
import React, { useRef, useState } from "react";
import { useAgendaAndMOMStyle } from "../../styles/summarix.style";
import { AgendaMOMType } from "../../Constants";

const AgendaAndMOM = (props) => {
  const {
    points: agendaPoint,
    setPoints: setAgendaPoints,
    isAgendaLoading,
    isAgendaEditable = true,
    isAgendaDeletable = true,
    isSortable = true,
    MOMType = AgendaMOMType.PRE_MOM,
  } = props;
  const classes = useAgendaAndMOMStyle();
  const dragItem = useRef(null);
  const dragOverItem = useRef(null);

  const handleSort = () => {
    let _agendaPoints = [...agendaPoint];

    const draggedAgenda = _agendaPoints.splice(dragItem.current, 1)[0];

    _agendaPoints.splice(dragOverItem.current, 0, draggedAgenda);

    dragItem.current = null;
    dragOverItem.current = null;

    setAgendaPoints(_agendaPoints);
  };

  return (
    <div className={classes.agendas}>
      {isAgendaLoading && <CircularProgress />}
      {agendaPoint.map((element, index) => (
        <AgendaBox
          key={index}
          agenda={element}
          agendaIndex={index}
          setAgendaPoints={setAgendaPoints}
          dragItem={dragItem}
          dragOverItem={dragOverItem}
          handleSort={handleSort}
          isAgendaEditable={isAgendaEditable}
          isSortable={isSortable}
          isAgendaDeletable={isAgendaDeletable}
          MOMType={MOMType}
        />
      ))}
    </div>
  );
};

const AgendaBox = (props) => {
  const classes = useAgendaAndMOMStyle();
  const {
    agenda,
    agendaIndex,
    setAgendaPoints,
    dragItem,
    dragOverItem,
    handleSort,
    isAgendaEditable,
    isAgendaDeletable,
    isSortable,
    MOMType,
  } = props;

  const [isAgendaOpen, setIsAgendaOpen] = useState(true);

  const handleMOMChange = (e) => {
    setAgendaPoints((pre) => {
      let newAgenda = pre.map((element, index) => {
        if (agendaIndex == index) {
          return MOMType == AgendaMOMType.PRE_MOM
            ? { ...element, preMOM: e.target.value }
            : { ...element, manualyUpdatedPostMOM: e.target.value };
        } else {
          return element;
        }
      });

      return newAgenda;
    });
  };
  const handleAgendaChange = (e) => {
    setAgendaPoints((pre) => {
      let newAgenda = pre.map((element, index) => {
        if (agendaIndex == index) {
          return {
            ...element,
            agendaTitle: e.target.value,
          };
        } else {
          return element;
        }
      });

      return newAgenda;
    });
  };

  const handleAgendaOpen = () => {
    setIsAgendaOpen((pre) => !pre);
  };

  const handleDeleteAgenda = () => {
    setAgendaPoints((prevPoints) => {
      return prevPoints.filter((point, index) => index !== agendaIndex);
    });
  };

  return (
    <div
      className={classes.agendaBox}
      draggable={isSortable}
      onDragStart={isSortable && (() => (dragItem.current = agendaIndex))}
      onDragEnter={isSortable && (() => (dragOverItem.current = agendaIndex))}
      onDragEnd={isSortable && handleSort}
      onDragOver={(e) => e.preventDefault()}
    >
      <div className={classes.agendaHeader}>
        <div className={classes.DragAndTextFieldBox}>
          {isSortable && (
            <div className={classes.dragIcon}>
              <DragHandle />
            </div>
          )}
          {isAgendaEditable && (
            <TextField
              className={classes.MOMtextField}
              value={agenda.agendaTitle || ""}
              onChange={handleAgendaChange}
              fullWidth
              InputProps={{ disableUnderline: true }}
            />
          )}
          {!isAgendaEditable && <div>{agenda.agendaTitle || ""}</div>}
        </div>
        <div>
          {isAgendaDeletable && (
            <span onClick={handleDeleteAgenda}>
              <Delete />
            </span>
          )}
          <span onClick={handleAgendaOpen}>
            {isAgendaOpen ? <ExpandLess /> : <ExpandMore />}
          </span>
        </div>
      </div>
      {isAgendaOpen && (
        <div className={classes.MOMbox}>
          <TextField
            className={classes.MOMtextField}
            value={MOMType == AgendaMOMType.PRE_MOM ?(agenda.preMOM || ""):(agenda.manualyUpdatedPostMOM || "")}
            onChange={handleMOMChange}
            fullWidth
            multiline
            maxRows={10}
            minRows={2}
          />
        </div>
      )}
    </div>
  );
};

export default AgendaAndMOM;
