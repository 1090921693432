import React, { useEffect, useState } from "react";
import { getAllContacts } from "../ABM_API";

const useUsersFetch = () => {
  const [usersList, setUsersList] = useState([]);
  const [isUsersListLoading, setIsUsersListLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setIsUsersListLoading(true);
      const res = await getAllContacts();
      setUsersList(res.data);
      setIsUsersListLoading(false);
    };

    fetchData();
  }, []);

  return { usersList, isUsersListLoading };
};

export default useUsersFetch;
