import { makeStyles, TextField } from "@material-ui/core";
import React, { useEffect, useState } from "react";

let style = makeStyles({
  timeField: {},
});
const CustomTimeField = (props) => {
  let classes = style();
  let { name, onChange, value, label, error = "" } = props;

  return (
    <div className={classes.timeField}>
      <TextField
        error={!!error}
        helperText={error}
        id="datetime-local"
        label={label}
        type="datetime-local"
        name={name}
        onChange={onChange}
        value={value}
        InputLabelProps={{
          shrink: true,
        }}
      />
    </div>
  );
};

export default CustomTimeField;
