const getPreviousMeetingMOM = () => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({
        "statusCode": 200,
        "statusMessage": "Success",
        "errorMsg": null,
        "data": [
            {
                "meetingAgendaId": 11,
                "meetingId": 12,
                "agendaTitle": "Chairman and Apologies",
                "preMOM": null,
                "systemGeneratedPostMOM": null,
                "manualyUpdatedPostMOM": null,
                "orderId": 1
            },
            {
                "meetingAgendaId": 12,
                "meetingId": 12,
                "agendaTitle": "Quorum and Notice",
                "preMOM": null,
                "systemGeneratedPostMOM": null,
                "manualyUpdatedPostMOM": null,
                "orderId": 2
            },
            {
                "meetingAgendaId": 13,
                "meetingId": 12,
                "agendaTitle": "Declarations of Interest",
                "preMOM": null,
                "systemGeneratedPostMOM": null,
                "manualyUpdatedPostMOM": null,
                "orderId": 3
            },
            {
                "meetingAgendaId": 14,
                "meetingId": 12,
                "agendaTitle": "Minutes Ratification Log",
                "preMOM": "",
                "systemGeneratedPostMOM": null,
                "manualyUpdatedPostMOM": null,
                "orderId": 4
            },
            {
                "meetingAgendaId": 15,
                "meetingId": 12,
                "agendaTitle": "Matters Arising",
                "preMOM": "",
                "systemGeneratedPostMOM": null,
                "manualyUpdatedPostMOM": null,
                "orderId": 5
            }
        ]
    });
    }, 1000);
  });
};

export default getPreviousMeetingMOM;
