import { makeStyles } from "@material-ui/styles";

export const useMeetingRegistrationStyle = makeStyles({
  meetingRegistrationForm: {
    paddingLeft: "10px",
    paddingRight: "10px",
    display: "flex",
    flexDirection: "column",
    gap: "12px",
  },
  timeFields: {
    display: "flex",
    gap: "16px",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
    padding: "16px 0px",
  },
  agendaFieldContainer: {
    background: "#e2e2e2",
    padding: "8px",
  },
  agendaTextField: {
    backgroundColor: "white",
  },
  agendaContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "4px",
  },
  button: {
    width: "auto",
    display: "flex",
    "& .MuiButton-label": {
      whiteSpace: "nowrap",
      textAlign: "center",
      display: "flex",
      justifyContent: "center",
    },
  },
});

export const useMeetingTimeFieldsStyle = makeStyles({
  timeFields: {
    display: "flex",
    gap: "16px",
    alignItems: "center",
  },
});
export const useAgendaFieldStyle = makeStyles({
  addAgendaBox: {
    display: "flex",
    gap: "16px",
    alignItems: "flex-start",
    paddingBottom:"16px"
  },
  addAgendaButton: {
    padding: "10px",
  },
});
export const useStepFormStyle = makeStyles({
  BackButtonArea: {
    width: "50%",
    display: "flex",
    justifyContent: "flex-start",
  },
  NextButtonArea: {
    width: "50%",
    display: "flex",
    justifyContent: "flex-end",
  },
  StepFormButtonArea: {
    width: "auto",
    display: "flex",
    paddingTop: "12px",
    paddingBottom: "12px",
    paddingLeft: "10px",
    paddingRight: "10px",
  },
  customButtons: {
    paddingLeft: "8px",
    display: "flex",
    gap: "5px",
  },
});

export const useMeetingAgendaSectionStyle = makeStyles({
  previousMOMButtonArea: {
    display: "flex",
    paddingBottom: "12px",
    justifyContent: "flex-end",
  },
});

export const useCustomOptionSetFieldStyle = makeStyles({
  optionSetField: {
    position: "relative",
  },
  options: {
    width: "100%",
    maxHeight: "150px",
    backgroundColor: "white",
    position: "absolute",
    display: "flex",
    flexDirection: "column",
    border: "0.6px solid #b2b2b2",
    borderRadius: "0px 0px 8px 8px",
    overflow: "hidden",
    zIndex: 1,
  },
  overflowYScroll: {
    overflowY: "scroll",
  },
  optionItem: {
    padding: "10px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#f0f0f0",
    },
  },
  selectedOptionItem: {
    backgroundColor: "#eaeaea",
    padding: "10px",
    cursor: "pointer",
  },
  addedOptionsList: {
    paddingTop: "6px",
    display: "flex",
    gap: "8px",
    flexWrap: "wrap",
  },
  addedOption: {
    paddingLeft: "8px",
    paddingRight: "8px",
    paddingTop: "4px",
    paddingBottom: "4px",
    borderRadius: "50px",
    border: "1px solid black",
    display: "flex",
    alignItems: "center",
    gap: "4px",
    fontSize: "12px",
  },
  closeIcon: {
    cursor: "pointer",
  },
  padding1: {
    padding: "10px",
  },
  padding2: {
    padding: "5px",
  },
});

export const useCustomSelectFieldStyle = makeStyles({
  optionSetField: {
    position: "relative",
  },
  options: {
    width: "100%",
    maxHeight: "150px",
    backgroundColor: "white",
    position: "absolute",
    display: "flex",
    flexDirection: "column",
    border: "0.6px solid #b2b2b2",
    borderRadius: "0px 0px 8px 8px",
    overflow: "hidden",
    zIndex: 1,
  },
  overflowYScroll: {
    overflowY: "scroll",
  },
  optionItem: {
    padding: "10px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#f0f0f0",
    },
  },
  selectedOptionItem: {
    backgroundColor: "#eaeaea",
    padding: "10px",
    cursor: "pointer",
  },
  addedOptionsList: {
    paddingTop: "6px",
    display: "flex",
    gap: "8px",
    flexWrap: "wrap",
  },
  addedOption: {
    paddingLeft: "8px",
    paddingRight: "8px",
    paddingTop: "4px",
    paddingBottom: "4px",
    borderRadius: "50px",
    border: "1px solid black",
    display: "flex",
    alignItems: "center",
    gap: "4px",
  },
  closeIcon: {
    cursor: "pointer",
  },
  padding1: {
    padding: "10px",
  },
  padding2: {
    padding: "5px",
  },
});

export const useAgendaAndMOMStyle = makeStyles({
  agendas: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    gap: "4px",
    minHeight: "180px",
  },
  agendaBox: {
    width: "100%",
    padding: "8px",
    background: "#e2e2e2",
    borderRadius: "4px",
    display: "flex",
    flexDirection: "column",
    gap: "4px",
  },
  MOMbox: {
    padding: "4px 4px",
    background: "white",
  },
  MOMtextField: {
    border: "0px",
    "&&&:before": {
      borderBottom: "none",
    },
    "&&:after": {
      borderBottom: "none",
    },
  },
  agendaHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  agendaFunctions: {
    display: "flex",
    alignItems: "center",
    gap: "12px",
  },
  DragAndTextFieldBox: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
  },
  dragIcon: {
    cursor: "move",
  },
});

export const useCustomModalUIStyle = makeStyles((theme) => ({
  modalContent: {
    position: "relative",
    width: "600px",
    maxWidth: "100%",
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(4),
    margin: "auto",
    outline: 0,
    top: "50%",
    transform: "translate(0, -50%)",
    maxHeight: "520px",
    height: "80%",
    display: "flex",
    flexDirection: "column",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottom: `1px solid ${theme.palette.divider}`,
    marginBottom: theme.spacing(2),
  },
  closeButton: {
    padding: 0,
  },
}));

export const useAddMeetingFormStyles = makeStyles({
  addMeetingForm: {
    paddingLeft: "10px",
    paddingRight: "10px",
    paddingBottom: "10px",
    display: "flex",
    flexDirection: "column",
    gap: "12px",
    overflowY: "scroll",
  },
  formButton: {
    paddingTop: "10px",
    display: "flex",
    justifyContent: "flex-end",
  },
});

export const usePostMeetingMOMReviewStyle = makeStyles({
  PostMOMContainer: {
    padding: "8px",
  },
  Buttons: {
    paddingTop: "12px",
    paddingBottom: "4px",
    display: "flex",
    justifyContent: "flex-end",
  },
});
