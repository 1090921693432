import { CircularProgress, TextField } from "@material-ui/core";
import React, { memo, useEffect, useRef, useState } from "react";
import { useCustomSelectFieldStyle } from "../../styles/summarix.style";

const CustomSelectField = memo(
  ({
    label,
    name,
    nameString,
    valueString,
    optionList,
    isListLoading,
    selectedOption,
    setSelectedOption,
    notFoundMessage,
    error = "",
    isDisabled = false,
  }) => {
    let classes = useCustomSelectFieldStyle();
    const [fieldValue, setFieldValue] = useState("");
    const [isListVisible, setIsListVisible] = useState(false);
    const [listToShow, setListToShow] = useState([]);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const wrapperRef = useRef(null);

    useEffect(() => {
      const handleClickOutside = (event) => {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
          onOptionSelected(selectedOption);
        }
      };
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [wrapperRef, selectedOption]);

    useEffect(() => {
      if (selectedOption != null) {
        setFieldValue(selectedOption[nameString]);
      }
    }, [selectedOption]);

    useEffect(() => {
      setListToShow(optionList);
    }, [optionList]);

    const handleFieldChange = (e) => {
      setFieldValue(e.target.value);
      if (e.target.value !== "") {
        let searchedResult = optionList.filter((item) =>
          item[nameString].toLowerCase().includes(e.target.value.toLowerCase())
        );
        setListToShow(searchedResult);
        setIsListVisible(true);
      } else {
        setSelectedOption(null);
        setListToShow(optionList);
        return;
      }

      setSelectedIndex(0);
    };

    const handleFieldClick = (e) => {
      if (!e.target.value) {
        setIsListVisible(true);
      }
    };

    const onOptionSelected = (option) => {
      if (option == null) {
        setIsListVisible(false);
        setFieldValue("");
        setSelectedIndex(0);
      } else {
        setSelectedOption(option);
        setIsListVisible(false);
        setFieldValue(option[nameString]);
        setSelectedIndex(0);
      }
    };

    const handleKeyDown = (e) => {
      if (listToShow.length === 0) return;
      if (e.key === "ArrowDown") {
        setSelectedIndex((prevIndex) => {
          return prevIndex + 1 == listToShow.length ? 0 : prevIndex + 1;
        });
      } else if (e.key === "ArrowUp") {
        setSelectedIndex((prevIndex) => {
          return prevIndex == 0 ? listToShow.length - 1 : prevIndex - 1;
        });
      } else if (e.key === "Enter") {
        if (selectedIndex >= 0) {
          onOptionSelected(listToShow[selectedIndex]);
        }
      }
    };

    return (
      <div className={classes.optionSetField}>
        <TextField
          error={!!error}
          helperText={error}
          label={label}
          name={name}
          value={fieldValue}
          onChange={handleFieldChange}
          onKeyDown={handleKeyDown}
          autoComplete="off"
          fullWidth
          disabled={isDisabled}
          onClick={handleFieldClick}
        />
        {isListVisible && !isListLoading && (
          <div
            ref={wrapperRef}
            className={`${classes.options} ${
              listToShow.length > 3 ? classes.overflowYScroll : ""
            }`}
          >
            {listToShow.map((item, index) => (
              <ClickableOption
                key={index}
                listToShow={listToShow}
                item={item}
                valueString={valueString}
                nameString={nameString}
                classes={classes}
                onOptionSelected={onOptionSelected}
                selectedIndex={selectedIndex}
              />
            ))}
            {listToShow.length == 0 && (
              <div className={classes.padding1}>{notFoundMessage}</div>
            )}
          </div>
        )}
        {isListLoading && isListVisible && (
          <div
            ref={wrapperRef}
            className={`${classes.padding2} ${classes.options}`}
          >
            <CircularProgress  size={20} />
          </div>
        )}
      </div>
    );
  }
);

const ClickableOption = (props) => {
  const {
    listToShow,
    item,
    classes,
    valueString,
    nameString,
    onOptionSelected,
    selectedIndex,
  } = props;
  return (
    <div
      className={
        listToShow[selectedIndex][valueString] == item[valueString]
          ? classes.selectedOptionItem
          : classes.optionItem
      }
      onClick={() => {
        onOptionSelected(item);
      }}
      value={item[valueString]}
    >
      {item[nameString]}
    </div>
  );
};

export default CustomSelectField;
