import { makeStyles, TextField } from "@material-ui/core";
import { isError } from "lodash";
import React from "react";

let style = makeStyles({
  textField: {
    width: "100%",
  },
});
const CustomTextField = (props) => {
  let classes = style();
  let {
    label = "",
    placeholder = "",
    name,
    value,
    onChange,
    onKeyDown = () => {},
    isMultiline = false,
    isDisabled = false,
    error = "",
  } = props;

  return (
    <div className={classes.textField}>
      <TextField
        error={!!error}
        helperText={error}
        label={label}
        placeholder={placeholder}
        name={name}
        value={value}
        multiline={isMultiline}
        onChange={onChange}
        fullWidth
        onKeyDown={onKeyDown}
        autoComplete="off"
        disabled={isDisabled}
      />
    </div>
  );
};

export default CustomTextField;
