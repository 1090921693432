import React, { useCallback, useState } from "react";
import { useAgendaFieldStyle } from "../styles/summarix.style";
import { Button } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import CustomTextField from "./FormFields/CustomTextField";

const AddAgendaField = (props) => {
  const { setAgendaPoints, error, meetingId } = props;

  const classes = useAgendaFieldStyle();
  const [agendaFieldText, setAgendaFieldText] = useState("");

  const handleAgendaFieldChange = (e) => {
    setAgendaFieldText(e.target.value);
  };

  const handleAddAgenda = (e) => {
    if (!!agendaFieldText) {
      setAgendaPoints((prevPoints) => {
        return [
          ...prevPoints,
          {
            meetingAgendaId: null,
            meetingId: meetingId,
            agendaTitle: agendaFieldText,
            preMOM: "",
            systemGeneratedPostMOM: null,
            manualyUpdatedPostMOM: null,
            orderId: null,
          },
        ];
      });
      setAgendaFieldText("");
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleAddAgenda();
    }
  };
  return (
    <div className={classes.addAgendaBox}>
      <CustomTextField
        placeholder="Add agenda"
        value={agendaFieldText}
        onChange={handleAgendaFieldChange}
        onKeyDown={handleKeyDown}
        error={error}
      />
      <Button
        disabled={!agendaFieldText}
        variant="outlined"
        onClick={handleAddAgenda}
      >
        <AddIcon />
      </Button>
    </div>
  );
};

export default AddAgendaField;
